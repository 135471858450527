@import "../../variables";
@import "bootstrap/scss/mixins/breakpoints";
@import "/cartridges/app_purina_core/cartridge/client/default/scss/components/fonts";

:root {
    --font-primary: "Antenna Purina", sans-serif;
    --font-secondary: "Antenna Purina", sans-serif;

    --text-primary: #fff; //Neutrals50
    --text-secondary: #000; //Primary400
    --text-disabled: #555; //Neutrals500

    --text-xs: 14px; //Heading-Text14
    --text-sm: 16px; //Heading-Text16
    --text-base: 20px; //Heading-Text20
    --text-lg: 24px; //Heading-Text24
    --text-xl: 32px; //Heading-Text32
    --text-2xl: 40px; //Heading-Text40
    --text-3xl: 48px; //Heading-Text48
    --text-3xxl: 64px; //Heading-Text48
    --text-4xl: 72px; //Heading-Text72

    --background-primary: #1e293b; //Primary500
    --background-secondary: #475569; //Primary400

    --error-primary: #df2935;
    --success-primary: #0aae59;
    --warning-primary: #f4d35e;

    --border-primary: #1e293b;
    --border-secondary: #475569;

    --p4p-brand-text-color:#8D7045;
    --primary-purina-black:#1E1E1E;
    --primary-purina-dark-grey: #2B2B2B;
}

.startup-kit-page {
    overflow: hidden;
    padding-bottom: 20px;
}

.startup-kit-page-margin-bottom-remove {
    /* stylelint-disable-next-line */
    padding-bottom: 0px;
}

.layout-minor-margins {
    overflow: hidden;
    padding: 0 20px;
    width: 100%;

    @include media-breakpoint-up(sm) {
        padding: 0 20px;
    }

    @include media-breakpoint-up(md) {
        padding: 0 33px;
    }

    @include media-breakpoint-up(lg) {
        padding: 0 2%;
    }
}

.layout-middle-aligned {
    margin: 0 auto;
    padding: 0;
    width: 100%;

    @include media-breakpoint-up(sm) {
        max-width: 100%;
    }

    @include media-breakpoint-up(md) {
        max-width: 100%;
    }

    @include media-breakpoint-up(lg) {
        max-width: 1000px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 1440px;
    }
}

.layout-full-width {
    width: 100%;
}

@font-face {
    font-family: "Antenna Purina";
    src: url("../../fonts/AntennaPurina-Thin.woff2") format("woff2"),
        url("../../fonts/AntennaPurina-Thin.woff") format("woff"),
        url("../../fonts/AntennaPurina-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Antenna Purina";
    src: url("../../fonts/AntennaPurina-ExtraLight.woff2") format("woff2"),
        url("../../fonts/AntennaPurina-ExtraLight.woff") format("woff"),
        url("../../fonts/AntennaPurina-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Antenna Purina";
    src: url("../../fonts/AntennaPurina-Light.woff2") format("woff2"),
        url("../../fonts/AntennaPurina-Light.woff") format("woff"),
        url("../../fonts/AntennaPurina-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Antenna Purina";
    src: url("../../fonts/AntennaPurina-Regular.woff2") format("woff2"),
        url("../../fonts/AntennaPurina-Regular.woff") format("woff"),
        url("../../fonts/AntennaPurina-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Antenna Purina";
    src: url("../../fonts/AntennaPurina-Medium.woff2") format("woff2"),
        url("../../fonts/AntennaPurina-Medium.woff") format("woff"),
        url("../../fonts/AntennaPurina-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Antenna Purina";
    src: url("../../fonts/AntennaPurina-Bold.woff2") format("woff2"),
        url("../../fonts/AntennaPurina-Bold.woff") format("woff"),
        url("../../fonts/AntennaPurina-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Antenna Purina";
    src: url("../../fonts/AntennaPurina-Black.woff2") format("woff2"),
        url("../../fonts/AntennaPurina-Black.woff") format("woff"),
        url("../../fonts/AntennaPurina-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Antenna Purina Condensed";
    src: url("../../fonts/AntennaPurinaCondensed-Regular.woff2") format("woff2"),
        url("../../fonts/AntennaPurinaCondensed-Regular.woff") format("woff"),
        url("../../fonts/AntennaPurinaCondensed-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Antenna Purina Condensed";
    src: url("../../fonts/AntennaPurinaCondensed-Black.woff2") format("woff2"),
        url("../../fonts/AntennaPurinaCondensed-Black.woff") format("woff"),
        url("../../fonts/AntennaPurinaCondensed-Black.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Purina Danforth";
    src: url("../../fonts/PurinaDanforth-Regular.woff2") format("woff2"),
        url("../../fonts/PurinaDanforth-Regular.woff") format("woff"),
        url("../../fonts/PurinaDanforth-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

